import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import '../styles/footer.scss'

export default function Footer() {


    return (
        <section className="footer">
            <div className="container">
                <div className="blocs">
                    <div className="bloc1">
                        <h4>Suivez-moi</h4>
                        <div>
                            <a href="https://www.facebook.com/Pixeldeveloppement73" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                        </div>
                    </div>
                    <div className="bloc2">
                        <div className="coordonnees">
                            <h4>Email</h4>
                            <a href="mailto:contact@pixeldeveloppement.fr" className="tel_contact">
                                <span className="iconSpan">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                                contact@pixeldeveloppement.fr
                            </a>
                        </div>
                    </div>
                    <div className="bloc3">
                        <div className="coordonnees">
                            <h4>Téléphone</h4>
                            <a href="tel:+33782403696" className="tel_contact">
                                <span className="iconSpan">
                                    <FontAwesomeIcon icon={faPhone} className="rotate-90" />
                                </span>
                                07 82 40 36 96
                            </a>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>2018 - 2021 © Pixel Developpement - <Link to="/mentions-legales">Mentions Légales</Link></p>
                </div>
            </div >
        </section>
    )

}